<template>
    <div class="row-search">
        <img loading="lazy" decoding="async" v-if="imageExists(assess.name)" :src="imagePath" alt="logo" />
        <font-awesome-icon v-else class="card-icon" :icon="assess.icon ? assess.icon : ['fas', 'file-invoice']" />
        <span>{{ assess.name }}</span>
    </div>
</template>

<script>
export default {
    name: "SearchCard",
    props: {
        OpenPreview: { type: Function },
        assess: { type: Object } /* handleDelete:{type: Function}, */,
        // imagePath: { type: String },
    },
    data() {
        return {};
    },
    methods: {
        imageExists(name) {
            try {
                if (name.toLowerCase().includes("kpmg")) {
                    // Use a different image path if 'name' includes 'special'
                    // console.log("KPMG");
                    this.imagePath = require("@/assets/assessmentsLogos/KPMG.svg");
                    return true;
                }
                if (name.toLowerCase().includes("workforce")) {
                    // Use a different image path if 'name' includes 'special'
                    // console.log("KPMG");
                    this.imagePath = require("@/assets/assessmentsLogos/workforce.png");
                    return true;
                }
                if (name.toLowerCase().includes("bbc")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/BBC.png`);
                    return true;
                }
                if (name.toLowerCase().includes("dragnet")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/dragnet.svg`);
                    return true;
                }
                if (name.toLowerCase().includes("at&t")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/AT&T.svg`);
                    return true;
                }
                if (name.toLowerCase().includes("amazon")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/amazon.svg`);
                    return true;
                }
                if (name.toLowerCase().includes("aws")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/amazon.svg`);
                    return true;
                }
                if (name.toLowerCase().includes("accenture")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/Accenture.svg`);
                    return true;
                }
                if (name.toLowerCase().includes("adobe")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/Adobe.svg`);
                    return true;
                }
                if (name.toLowerCase().includes("adp")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/ADP.svg`);
                    return true;
                }
                if (name.toLowerCase().includes("aig")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/AIG.svg`);
                    return true;
                }
                if (name.toLowerCase().includes("alcatel")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/alcatel.svg`);
                    return true;
                }
                if (name.toLowerCase().includes("nnpc")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/NNPC.svg`);
                    return true;
                }
                if (name.toLowerCase().includes("nvidia")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/Nvidia.svg`);
                    return true;
                }
                if (name.toLowerCase().includes("oracle")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/oracle.svg`);
                    return true;
                }
                if (name.toLowerCase().includes("pwc")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/PWC.svg`);
                    return true;
                }
                if (name.toLowerCase().includes("gtco")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/GTCO.svg`);
                    return true;
                }
                if (name.toLowerCase().includes("zenith bank")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/ZenithBank.svg`);
                    return true;
                }
                if (name.toLowerCase().includes("deloitte")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/Deloitte.svg`);
                    return true;
                }
                if (name.toLowerCase().includes("amdocs")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/amdocs.svg`);
                    return true;
                }
                if (name.toLowerCase().includes("bayer")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/Bayer.svg`);
                    return true;
                }
                if (name.toLowerCase().includes("blackrock")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/BlackRock.svg`);
                    return true;
                }
                if (name.toLowerCase().includes("capgemini")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/Capgemini.svg`);
                    return true;
                }
                if (name.toLowerCase().includes("cisco")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/Cisco.svg`);
                    return true;
                }
                if (name.toLowerCase().includes("cognizant")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/Cognizant.svg`);
                    return true;
                }
                if (name.toLowerCase().includes("cyient")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/Cyient.svg`);
                    return true;
                }
                if (name.toLowerCase().includes("dell")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/Dell.svg`);
                    return true;
                }
                if (name.toLowerCase().includes("ericsson")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/Ericsson.svg`);
                    return true;
                }
                if (name.toLowerCase().includes("sutherland")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/eversheds-sutherland.svg`);
                    return true;
                }
                if (name.toLowerCase().includes("eversheds-sutherland")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/eversheds-sutherland.svg`);
                    return true;
                }
                if (name.toLowerCase().includes("expedia")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/Expedia.svg`);
                    return true;
                }
                if (name.toLowerCase().includes("google")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/Google.svg`);
                    return true;
                }
                if (name.toLowerCase().includes("hackerrank")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/hackerrank.svg`);
                    return true;
                }
                if (name.toLowerCase().includes("hcl")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/HCL_Technologies.svg`);
                    return true;
                }
                if (name.toLowerCase().includes("hp")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/hp.svg`);
                    return true;
                }
                if (name.toLowerCase().includes("hsbc")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/hsbc.svg`);
                    return true;
                }
                if (name.toLowerCase().includes("huawei")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/Huawei.svg`);
                    return true;
                }
                if (name.toLowerCase().includes("ibm")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/ibm.svg`);
                    return true;
                }
                if (name.toLowerCase().includes("infosys")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/Infosys.svg`);
                    return true;
                }
                if (name.toLowerCase().includes("intel")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/Intel.svg`);
                    return true;
                }
                if (name.toLowerCase().includes("mindtree")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/mindtree.svg`);
                    return true;
                }
                if (name.toLowerCase().includes("quest")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/QuestGlobal.svg`);
                    return true;
                }
                if (name.toLowerCase().includes("global")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/QuestGlobal.svg`);
                    return true;
                }
                if (name.toLowerCase().includes("reuters")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/reuters.svg`);
                    return true;
                }
                if (name.toLowerCase().includes("sap")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/sap.svg`);
                    return true;
                }
                if (name.toLowerCase().includes("sony")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/Sony.svg`);
                    return true;
                }
                if (name.toLowerCase().includes("visa")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/Visa.svg`);
                    return true;
                }
                if (name.toLowerCase().includes("vmware")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/VMware.svg`);
                    return true;
                }
                if (name.toLowerCase().includes("wipro")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/Wipro.svg`);
                    return true;
                }
                if (name.toLowerCase().includes("zte")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/zte.svg`);
                    return true;
                }
                if (name.toLowerCase().includes("gtco")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/GTCO.svg`);
                    return true;
                }
                if (name.toLowerCase().includes("/")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/Error Data Checking.svg`);
                    return true;
                }
                if (name.toLowerCase().includes("verbal")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/Verbal.svg`);
                    return true;
                }
                if (name.toLowerCase().includes("customer service")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/Customer Service.svg`);
                    return true;
                }
                if (name.toLowerCase().includes("motivation")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/Motivation.svg`);
                    return true;
                }
                if (name.toLowerCase().includes("entrepreneurship")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/Entrepreneurship.svg`);
                    return true;
                }
                if (name.toLowerCase().includes("time management")) {
                    this.imagePath = require(`@/assets/assessmentsLogos/Time Management.svg`);
                    return true;
                }

                // Use a dynamic import to check if the image file exists
                this.imagePath = require(`@/assets/assessmentsLogos/${name}.svg`);
                return true;
            } catch (error) {
                return false;
            }
        },
    },
    mounted() {},
};
</script>

<style scoped>
.row-search {
    display: flex;
    justify-content: start;
    align-items: center;
    border-bottom: 2px solid #edeff2e3;
    gap: 30px;
    width: 100%;
    height: 70px;
    padding: 15px;

    img {
        height: 20px;
        width: 20px;
        margin: 0;
    }
}

.row-search:hover {
    cursor: pointer;
    background: #f5f6f8;
}

.card-icon {
    font-size: 20px;
    color: #2196f3;
}
</style>
